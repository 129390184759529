@tailwind base;

@layer base {
    html,
    body,
    #root {
        font-family: 'Poppins';
        @apply bg-white dark:bg-black flex flex-1 min-h-screen;
    }

    mark {
        @apply bg-highlight-yellow;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
}

/* Import font faces here */

@tailwind components;

@layer components {
    .btn {
        @apply border p-5;
    }
}

@tailwind utilities;
